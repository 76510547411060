@import 'fonts.css';
@import 'statusbar.css';


@tailwind base;

@tailwind components;

#page {
    @apply text-sm;
    font-family: "Open Sans", sans-serif;
    width: 889px;
    margin-top: 15px;
    margin-left: 30px;
    color: #666666;
}

.sidebanner {
    @apply ml-8;
    width: 130px;
    margin-top: 248px;
}
.sidebanner .title {
    @apply px-2 py-1 text-center text-xs font-semibold;
    background-color: #b3b3b3;
    color: #ffffff;
}

.rba-header {
    background-image: url(/images/header_bar.png);
    background-repeat: no-repeat;
    height: 115px;
}
.subheader {
    @apply flex justify-between items-center px-3;
    background-color: #F2F2F2;
    min-height: 45px;
    border-left: 1px solid #CDCDCD;
    border-right: 1px solid #CDCDCD;
}
nav {
    border: medium none;
}
.nav {
    background-image: url("/images/menu_bar.png");
    background-repeat: no-repeat;
    height: 35px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 0 10px;
}
nav .nav ul {
    @apply flex items-center h-full;
}
nav .nav ul li:first-child {
    border-left: 1px solid #c0c0c0;
}
nav .nav ul li {
    @apply h-full flex items-center;
    border-right: 1px solid #c0c0c0;
    padding: 0 10px;
}
nav .nav ul li.menubutton {
    @apply font-bold text-lg;
    display: none;
    text-decoration: none;
    font-family: Arial, serif;
    color: #5f5f5f;
}
nav .nav ul li.menubutton svg {
    @apply mr-2;
}
nav .nav ul li a {
    text-decoration: none;
    font: bold 13px Arial;
    color: #5f5f5f;
}
nav .nav ul li.current {
    background-image: url("/images/button_active.png");
    background-repeat: repeat-x;
}
nav .nav ul li.current a {
    @apply text-white;
}
.nav-title {
    @apply text-right text-sm pr-2;
    color: #666666;
}

.breadcrumb {
    @apply text-sm;
    color: #b3b3b3;
}
.breadcrumb a {
    @apply text-purple;
    padding-left: 10px;
}

.breadcrumb li:before {
    padding-left: 10px;
    content: "»";
}
.breadcrumb li:nth-child(1):before,
.breadcrumb li:nth-child(2):before
{
    padding-left: 0;
    content: "";
}

.bookmarks ul li {
    @apply ml-1;
}

.footer ul {
    @apply flex justify-around;
}
.footer ul li {
    @apply px-2 leading-4;
    border-right: 1px solid #c0c0c0;
}
.footer ul li:last-child {
    border-right: medium none;
}
.footer a {
    @apply text-purple text-xs;
}

.header {
    @apply font-semibold px-2;
    border: 1px solid #e3e3e3;
    border-radius: 5px 8px 0 0;
    background: rgba(236,236,236,1);
    background: linear-gradient(0deg, rgba(236,236,236,1) 0%, rgba(255,255,255,1) 100%);
    color: #666;
}

.abc {
    @apply flex justify-between w-full;
}
.abc a {
    color: #cccccc;
}

.suchbox {
    @apply font-semibold px-4 pt-3 pb-4;
    border: 1px solid #e3e3e3;
    border-radius: 5px 8px 0 0;
    background: rgba(236,236,236,1);
    background: linear-gradient(0deg, rgba(236,236,236,1) 0%, rgba(255,255,255,1) 100%);
    color: #b4b4b4;
}
.suchbox input {
    @apply w-full mt-2 leading-5 py-1 px-4;
    border: 1px solid #b3b3b3;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(234,234,234,1) 100%);
}

.button-search {
    @apply flex items-center text-white py-1 pl-4 pr-2 italic font-semibold rounded shadow;
    border: 1px solid #a20042;
    background: rgb(191,78,122);
    background: linear-gradient(180deg, rgba(191,78,122,1) 0%, rgba(159,0,58,1) 23%, rgba(255,142,166,1) 100%);
}
.button-search:hover {
    @apply text-gray-200;
}

.content {
    @apply flex mt-4;
}
.col-left {
    @apply w-1/2 mr-3;
}
.col-right {
    @apply w-1/2 ml-3;
}

.image-no-scale {
    max-width: none;
}

.box {
    @apply p-4;
    background-color: #f8f8f8;
    border: 1px solid #b3b3b3;
}

.grid-2 {
    @apply grid grid-cols-2;
}

.purple-square li:before {
    @apply text-purple inline text-xs;
    content: "\25A0";
    width: 1.2em;
    margin-left: -1.2em
}

.abc_link {
    @apply text-purple ml-2;
}
.flex-in-full {
    @apply flex;
}

.grid-sidebar {
    @apply grid;
    grid-template-columns: 215px 1fr;
    grid-gap: 1rem;
}

.grid-sidebar > .sidebar {
    border: 1px solid #e2e2e2;
    border-bottom: 15px solid #e2e2e2;
    background-image: url("/images/callcenter.png");
    background-repeat: no-repeat;
    padding-top: 150px;
    background-position: center 7px;
}

.faq-answer {
    @apply p-4;
    background-color: #efefef;
}
.faq-button {
    @apply text-purple cursor-pointer;
}

.formbox {
    @apply border border-purple py-2 px-3;
    background-color: #f8f8f8;
}

.formbox input, .formbox textarea {
    @apply px-2;
    border: 1px solid #606060;
    color: #808080;
}

.button-send {
    @apply w-full text-center bg-white rounded py-1;
    border: 1px solid #e0e0e0;
    color: #3d3d3d;
}

.seopage-footer {
    @apply py-1 px-2 mt-1 text-xs;
    background-color: #f8f8f8;
    border: 1px dotted #b3b3b3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hideGoogleMaps {
    @apply hidden;
}

.company-wrap {
    @apply pb-6;
}
.company-header {
    @apply flex justify-between px-2 py-1;
    background: rgb(246,246,246);
    background: linear-gradient(0deg, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
    border: 1px solid #cfcfcf;
}
.company-header a {
    @apply text-purple underline font-bold;
}

.company-logo {
    @apply float-right pl-2 pb-2;
}
.company-info {
    @apply p-2;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #cfcfcf;
}
.company-address {
    @apply font-semibold pb-2;
}
.company-categories strong {
    @apply block text-purple;
}
.company-categories span {
    @apply pl-6 inline-block;
}
.company-contactinfo {
    @apply flex w-full;
}
.company-contactinfo > div {
    @apply w-1/4 pt-3 truncate;
}
.company-contactinfo a {
    @apply text-purple;
}
.company-contactinfo strong {
    @apply font-semibold;
    color: #b3b3b3;
}

.pagination-wrapper, .pagination {
    @apply flex;
}
.pagination-wrapper strong, .pagination strong {
    @apply pr-2;
}
.pagination-wrapper ul {
    @apply flex;
}
.pagination-wrapper ul li, .pagination li {
    @apply px-1;
}
.pagination-wrapper ul li.disabled {
    @apply text-gray-500;
}
.pagination-wrapper ul li.active, .pagination li.active {
    @apply font-bold text-purple;
}

.company-wrapper {
    @apply flex w-full h-full;
    background-color: #f8f8f8;
}
.company-submenu ul {
    @apply flex justify-end mb-3;
}
.company-submenu ul li {
    @apply text-xs font-semibold text-purple ml-4;
}
.company-submenu ul li:hover {
    @apply underline cursor-pointer;
}

#map {
    @apply flex items-center justify-center flex-col h-full w-full;
    min-height: 200px;
}

.article-content a {
    @apply text-purple;
}

.company-contactform {
    @apply w-full p-4;
}
.company-contactform .form-field {
    @apply w-full leading-loose pb-2;
}
.company-contactform .form-field:last-child {
    @apply pb-0;
}
.company-contactform .form-field input, .company-contactform .form-field textarea {
    @apply w-full px-2 py-1 rounded;
}
.company-contactform .form-field textarea {
    @apply leading-normal;
}
.company-contactform .form-field button {
    @apply w-full text-center rounded;
    border: 1px solid #606060;
    background-color: #f0f0f0;
}

.hpm {
    @apply p-2;
    border: 2px dotted #DFDFDF;
}

.hpm a {
    @apply text-purple;
}
.hpm a:hover {
    @apply underline;
}

.gradientbox {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgb(241,241,241), rgb(255,255,255) 4rem);
    border: 1px solid #ccc;
}
.gradientbox h3 {
    @apply font-semibold;
}

@media  (max-width: 949px) {
    #page {
        @apply w-full m-0;
    }
    nav .nav {
        @apply h-auto p-0;
        background-image: none;
    }
    nav .nav ul {
        @apply flex-col;
    }
    nav .nav ul li {
        @apply w-full h-auto hidden;
        background-image: url("/images/menu_bar.png");
        background-position: center;
        height: 35px;
    }
    nav .nav ul li.menubutton {
        @apply flex cursor-pointer
    }
    nav .nav ul li.menu-visible {
        display: flex;
    }
    nav .nav ul li a {
        @apply text-lg;
    }
    .nav-title {
        display: none;
    }
    footer .footer ul {
        @apply flex-col;
    }
    footer .footer ul li {
        @apply py-1;
    }
    .content {
        @apply block px-4;
    }
    .col-left, .col-right {
        @apply w-full m-0;
    }
    .grid-2 {
        @apply block;
    }
    .flex-in-full {
        @apply block;
    }
    .flex-wrap-mobile {
        @apply flex-wrap;
    }
}

.notify {
    @apply mb-4 border px-2 py-1 font-bold;
}
.notify_success {
    @apply bg-purple border-purple text-white;
}
.notify_failure {
    @apply bg-red-700 border-red-800 text-white;
}

.company-whatsapp {
    max-width: 155px;
}

@tailwind utilities;

